// Helper to do an ajax post with json content instead of form data
//#region $.PostJson

$.postJson = function (url, data, success, error) {
    return $.ajax({
        url: url,
        type: "POST",
        dataType: "json", // expected format for response
        contentType: "application/json", // send as JSON
        data: data,
        success: success,
        error: error,
        complete: function () {
        },
        beforeSend: function () {
        }
    });
};
//#endregion